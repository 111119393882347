<div class="hide-labels mb-3">
  <div class="d-none d-lg-block">
    <table class="table table-sm hide-updatedBy w-100">
      <tbody class="border-bottom" style="background-color: var(--bs-table-bg)">
        <tr>
          <td class="border-0"></td>
          <td class="border-0"></td>
          <td class="align-bottom" colspan="5">
            Shares that may be acquired pursuant to:
          </td>
          <td class="border-0"></td>
        </tr>
        <tr>
          @for (column of columns; track column) {
            <td
              class="align-bottom py-2"
              scope="col"
              [style.width]="column.width"
            >
              {{ column.label }}
            </td>
          }
        </tr>
        @for (field of field.fieldGroup; track field; let i = $index) {
          <tr>
            <td
              *ngIf="
                getField(
                  'Signatory_CompanySecuritiesRtaAwardType',
                  i
                ) as subfield
              "
              class="align-top pt-3"
            >
              <formly-field [field]="subfield"></formly-field>
            </td>
            <td
              *ngIf="
                getField(
                  'Signatory_CompanySecuritiesRtaStockClass',
                  i
                ) as subfield
              "
              class="align-top pt-3"
            >
              <formly-field [field]="subfield"></formly-field>
            </td>
            <td class="align-top pt-3">
              <formly-field
                *ngIf="
                  getField(
                    'signatory_companysecuritiesrtaexoptionwarrantright',
                    i
                  ) as subfield
                "
                [field]="subfield"
              />
            </td>
            <td class="align-top pt-3">
              <formly-field
                *ngIf="
                  getField(
                    'signatory_companysecuritiesrtasecurityconversion',
                    i
                  ) as subfield
                "
                [field]="subfield"
              />
            </td>
            <td class="align-top pt-3">
              <formly-field
                *ngIf="
                  getField(
                    'signatory_companysecuritiesrtaterminationtrustacct',
                    i
                  ) as subfield
                "
                [field]="subfield"
              />
            </td>
            <td class="align-top pt-3">
              <formly-field
                *ngIf="
                  getField(
                    'signatory_companysecuritiesrtasettlement',
                    i
                  ) as subfield
                "
                [field]="subfield"
              />
            </td>
            <td class="align-top pt-3">
              <formly-field
                *ngIf="
                  getField('signatory_companysecuritiesrtaother', i) as subfield
                "
                [field]="subfield"
              />
            </td>
            <td class="align-top pt-3">
              <button
                class="btn cx-btn cx-btn--ghost cx-btn--icon"
                type="button"
                (click)="removeGroup(i)"
              >
                <svg
                  cdsIcon="trash-can"
                  class="text-danger"
                  fill="currentColor"
                  size="16"
                ></svg>
              </button>
            </td>
          </tr>
        }
      </tbody>
    </table>

    <button
      class="btn btn-outline-primary cx-btn"
      [class.w-100]="!field.fieldGroup?.length"
      [ngStyle]="field.fieldGroup?.length ? {} : { borderStyle: 'dashed' }"
      type="button"
      (click)="addGroup()"
    >
      {{
        field.fieldGroup?.length
          ? "Add another security"
          : "Add one or more securities"
      }}
      <svg cdsIcon="add" fill="currentColor" size="16"></svg>
    </button>
  </div>

  <div class="d-lg-none">
    <div
      *ngFor="let record of formControl.value; let i = index"
      class="card align-items-center flex-row ps-3 pe-2"
      role="button"
      style="margin-top: 2px; height: 3rem"
      (click)="openRepeatForm(i)"
    >
      <div class="flex-fill overflow-hidden">
        <ng-container
          *ngFor="let item of record | keyvalue: keepOrder; let j = index"
        >
          <div *ngIf="j < 2" [ngClass]="{ 'small text-muted': j }">
            {{ item.value }}
          </div>
        </ng-container>
      </div>
      <button
        class="btn cx-btn cx-btn--ghost cx-btn--icon ms-2"
        type="button"
        (click)="removeGroup(i)"
      >
        <svg cdsIcon="trash-can" fill="currentColor" size="16"></svg>
      </button>
    </div>

    <button
      class="btn btn-outline-primary cx-btn my-3 w-100"
      [ngStyle]="field.fieldGroup?.length ? {} : { borderStyle: 'dashed' }"
      type="button"
      (click)="openRepeatForm()"
    >
      {{
        field.fieldGroup?.length
          ? "Add additional securities"
          : "Add one or more securities"
      }}
      <svg cdsIcon="add" fill="currentColor" size="16"></svg>
    </button>
  </div>

  @if ((showError$ | async) && field.formControl.value.length === 0) {
    <div class="missing-answer d-flex gap-2 py-2 text-danger">
      <svg cdsIcon="warning--filled" fill="currentColor" size="16"></svg>
      {{ requiredFieldLabel$ | async }}
    </div>
  }

  @if (updatedBy$ | async; as updatedBy) {
    <div
      class="alert alert-warning mt-2"
      [innerHTML]="updatedBy"
      style="max-width: 640px"
    ></div>
  }
</div>
