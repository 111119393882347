<div class="hide-labels mb-3">
  <table class="table table-sm hide-updatedBy">
    <tbody class="border-bottom" style="background-color: var(--bs-table-bg)">
      @if (showTableHeader) {
        <tr>
          @for (column of columns; track column.key) {
            <td
              class="align-bottom py-2"
              scope="col"
              [style.width]="column.width"
            >
              {{ column.label }}
            </td>
          }
          @if (preventColumnGrowth) {
            <td></td>
          }
        </tr>
      }
      @for (field of field.fieldGroup; track field; let i = $index) {
        <tr>
          @if (getField("signatory_directorshipcompany", i); as subfield) {
            <td class="align-top pt-3">
              <formly-field [field]="subfield"></formly-field>
            </td>
          }
          @if (
            getField("signatory_directorshipappointmentdate", i);
            as subfield
          ) {
            <td class="align-top pt-3">
              <formly-field [field]="subfield"></formly-field>
            </td>
          }
          <td class="align-top pt-3">
            @if (getField("signatory_directorshipcurrent", i); as subfield) {
              <formly-field [field]="subfield"></formly-field>
            }
            @if (
              getField("signatory_directorshipresignationdate", i);
              as subfield
            ) {
              @if (!subfield.props?.hidden) {
                <div class="ms-3 mt-n2">
                  <div class="text-muted">Date of Resignation:</div>
                  <formly-field [field]="subfield"></formly-field>
                </div>
              }
            }
          </td>
          @if (getField("signatory_directorshipcompetitor", i); as subfield) {
            <td class="align-top pt-3">
              <formly-field [field]="subfield"></formly-field>
            </td>
          }
          <td class="align-top pt-3" style="width: 4rem">
            <button
              class="btn cx-btn cx-btn--ghost cx-btn--icon"
              type="button"
              (click)="removeGroup(i)"
            >
              <svg
                cdsIcon="trash-can"
                class="text-danger"
                fill="currentColor"
                size="16"
              ></svg>
            </button>
          </td>
          @if (preventColumnGrowth) {
            <td></td>
          }
        </tr>
      }
    </tbody>
  </table>

  <button
    class="btn btn-outline-primary cx-btn"
    [class.w-100]="!field.fieldGroup?.length"
    [ngStyle]="field.fieldGroup?.length ? {} : { borderStyle: 'dashed' }"
    type="button"
    (click)="addGroup()"
  >
    {{ field.fieldGroup?.length ? labels.add_more : labels.add_first }}
    <svg cdsIcon="add" fill="currentColor" size="16"></svg>
  </button>

  @if ((showError$ | async) && field.formControl.value.length === 0) {
    <div class="missing-answer d-flex gap-2 py-2 text-danger">
      <svg cdsIcon="warning--filled" fill="currentColor" size="16"></svg>
      {{ requiredFieldLabel$ | async }}
    </div>
  }

  @if (updatedBy$ | async; as updatedBy) {
    <div
      class="alert alert-warning mt-2"
      [innerHTML]="updatedBy"
      style="max-width: 640px"
    ></div>
  }
</div>
