import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { IconModule } from 'carbon-components-angular';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
  Subject,
  catchError,
  filter,
  finalize,
  forkJoin,
  map,
  of,
  switchMap,
  tap,
  timeout,
} from 'rxjs';
import {
  ActiveProjectService,
  CurrentUserService,
  ProjectTypeService,
  ProjectsService,
} from 'src/app/core/services';
import { AppRoutes, Modal, ProjectRoutes } from 'src/app/models/enums';
import { ProjectSummary, ProjectType } from 'src/app/models/interfaces';

@Component({
  selector: 'app-reset-project',
  standalone: true,
  imports: [CommonModule, IconModule, ReactiveFormsModule],
  templateUrl: './reset-project.component.html',
})
export class ResetProjectComponent {
  form = new FormGroup({
    projectId: new FormControl<string>('', { nonNullable: true }),
    confirm: new FormControl<string>('', {
      nonNullable: true,
      validators: [
        Validators.required,
        confirmationValidator('Confirm and reset'),
      ],
    }),
  });
  failed$ = new Subject();
  projects$ = this.activeProjectService.project$.pipe(
    filter(Boolean),
    switchMap(({ clientNumber }) =>
      clientNumber
        ? forkJoin([
            this.projectsService.getProjectsSummary(clientNumber),
            this.projectTypeService.fetchProjectTypes(),
          ]).pipe(
            map(([projects, projectTypes]) =>
              this.filterProjects(projects, projectTypes),
            ),
          )
        : of([]),
    ),
  );
  submitting$ = new Subject();

  constructor(
    private activeProjectService: ActiveProjectService,
    private bsModalService: BsModalService,
    private currentUserService: CurrentUserService,
    private projectsService: ProjectsService,
    private projectTypeService: ProjectTypeService,
    private router: Router,
  ) {
    const userId = this.currentUserService.currentUser?.id;
    if (userId) {
      this.projectsService
        .fetchMyProjects(userId)
        .pipe(takeUntilDestroyed())
        .subscribe();
    }
  }

  closeModal(): void {
    this.bsModalService.hide(Modal.ResetProject);
  }

  submit(): void {
    this.failed$.next(false);
    this.submitting$.next(true);

    const fromProjectId = this.form.controls.projectId.value;
    const toProjectId = this.activeProjectService.project?.id;
    const submit$ =
      fromProjectId && toProjectId
        ? this.projectsService.copyProjectData(fromProjectId, toProjectId)
        : this.activeProjectService.resetProject();
    submit$
      .pipe(
        timeout({ each: 3_000, with: () => of(null) }),
        catchError((error) => {
          console.error(error);
          this.failed$.next(true);
          return of();
        }),
        tap(() =>
          this.router.navigate([
            './',
            AppRoutes.PROJECT,
            toProjectId,
            ProjectRoutes.LOCKED,
          ]),
        ),
        finalize(() => this.submitting$.next(false)),
      )
      .subscribe(() => this.closeModal());
  }

  private detectYear(projectTypeName?: string): number | null {
    if (!projectTypeName) {
      return null;
    }

    // Detect 4 digits at the beginning of a string.
    const match = projectTypeName.match(/^\d{4}/);

    // If there is a match, return it as a number.
    // Otherwise, return null.
    return match ? Number(match[0]) : null;
  }

  private filterProjects(
    projects: ProjectSummary[],
    projectTypes: ProjectType[],
  ): ProjectSummary[] {
    const currentProjectType = projectTypes.find(
      ({ id }) => id === this.activeProjectService.project?.projectTypeId,
    );
    const currentSeason = this.detectYear(currentProjectType?.projectTypeName);
    return projects.filter(({ projectTypeId }) => {
      const projectType = projectTypes.find(({ id }) => id === projectTypeId);
      const season = this.detectYear(projectType?.projectTypeName);
      return season && currentSeason && season < currentSeason;
    });
  }
}

function confirmationValidator(confirmation: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value === confirmation ? null : { confirmation: false };
  };
}
