export enum Modal {
  ConfirmationModal = 1,
  ConfirmSaveChanges,
  ChangeProjectStatus,
  Intercept,
  InvalidAnswer,
  InviteRespondent,
  Maintenance,
  MarkComplete,
  PreviewQuestionnaire,
  ProjectSettings,
  ReopenQuestionnaire,
  RepeaterForm,
  ReviewChanges,
  ResetProject,
  RespondentBulkOperations,
  RollBackQuestionnaire,
  UnsavedChanges,
}
