import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FieldArrayType, FormlyFieldConfig } from '@ngx-formly/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { map, takeUntil } from 'rxjs';
import {
  ActivePageService,
  ActiveProjectService,
  QuestionnaireService,
  REPEAT_GROUP_SUFFIX,
} from 'src/app/core/services';
import { Modal } from 'src/app/models/enums';
import { QuestionnaireAnswer } from 'src/app/models/interfaces';
import { RepeatFormComponent } from '../..';

@Component({
  selector: 'app-right-to-acquire',
  templateUrl: './right-to-acquire.component.html',
})
export class RightToAcquireComponent extends FieldArrayType {
  requiredFieldLabel$ = this.route.data.pipe(
    map((data) =>
      data['allowIncompletePage']
        ? 'This answer is missing'
        : 'This field is required',
    ),
  );
  showError$ = this.questionnaireService.showErrors$;
  updatedBy$ = this.activePageService.answerChanges$.pipe(
    map((answerChanges) => {
      const answerChange = answerChanges.find(
        ({ respondentInfoAnswer, variableName }) =>
          variableName?.toLowerCase() === this.fieldKey ||
          respondentInfoAnswer.repeatGroupName === this.key,
      );

      return answerChange
        ? this.formatUpdatedByLabel(
            answerChange.respondentInfoAnswer as Required<QuestionnaireAnswer>,
          )
        : null;
    }),
  );

  columns = [
    { label: 'Award type' },
    { label: 'Type of Underlying Securities' },
    { label: 'Exercise of an option, warrant or right', width: '9rem' },
    { label: 'Conversion of a security', width: '9rem' },
    { label: 'Revocation/ termination of account', width: '9rem' },
    { label: 'Settlement of any stock', width: '9rem' },
    { label: 'Other contractual rights', width: '9rem' },
    { label: '', width: '4rem' },
  ];

  get fieldArray() {
    return this.field.fieldArray as FormlyFieldConfig;
  }

  get fieldKey() {
    return (this.field.key as string).replace(REPEAT_GROUP_SUFFIX, '');
  }

  constructor(
    private activePageService: ActivePageService,
    private activeProjectService: ActiveProjectService,
    private modalService: BsModalService,
    private questionnaireService: QuestionnaireService,
    private route: ActivatedRoute,
  ) {
    super();
  }

  addGroup(): void {
    // Certain CE fields require numeric counter of repeater groups
    // to be updated in real time for usage property to work correctly.
    // Adding a "band-aid" here for conditional fields to work.
    if (
      this.field.parent?.model &&
      !this.field.parent.model.hasOwnProperty(this.fieldKey)
    ) {
      this.field.parent.model[this.fieldKey] = 1;
    }

    this.add();
  }

  getField(name: string, index: number) {
    if (Array.isArray(this.field.fieldGroup)) {
      const row = this.field.fieldGroup[index];
      const fieldConfig = row.fieldGroup?.find(
        ({ key }) => key === name.toLowerCase(),
      );
      return fieldConfig;
    }

    return null;
  }

  keepOrder(): number {
    return 0;
  }

  openRepeatForm(index?: number): void {
    const fields =
      index !== undefined && this.field.fieldGroup
        ? this.field.fieldGroup[index].fieldGroup
        : this.fieldArray.fieldGroup;

    const model =
      index !== undefined
        ? { ...this.formControl.at(index).getRawValue() }
        : {};

    const modalRef = this.modalService.show(RepeatFormComponent, {
      class: 'modal-md modal-dialog-centered',
      id: Modal.RepeaterForm,
      initialState: {
        fields,
        form: new FormGroup({}),
        model,
        operation: index === undefined ? 'add' : 'update',
      },
    });

    modalRef.content?.onSave$
      .pipe(takeUntil(modalRef.content.destroyed$))
      .subscribe((value) => {
        this.formControl.markAsDirty();
        if (index !== undefined) {
          this.formControl.at(index).patchValue(value);
          // We need to notify formly about model changes
          // hence resetModel call below.
          // if (this.options.resetModel) {
          //   this.options.resetModel(this.form.getRawValue());
          // }
        } else {
          this.add(undefined, value);
        }
      });
  }

  removeGroup(index: number): void {
    this.formControl.markAsTouched();
    if (this.formControl.length === 1) {
      Object.keys(this.formControl.at(0).getRawValue()).forEach((key) => {
        this.formControl.at(0).get(key)?.setValue('', { emitEvent: false });
      });
      this.formControl.markAsDirty();
      this.formControl.updateValueAndValidity();
    }
    this.remove(index, { markAsDirty: true });
    this.formControl.updateValueAndValidity();
  }

  private formatUpdatedByLabel(answer: Required<QuestionnaireAnswer>): string {
    const { created, createdByUser } = answer;
    const date = new Date(created).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
    });
    const name = this.activeProjectService.project?.projectPeople.find(
      ({ personId }) => personId.toLowerCase() === createdByUser.toLowerCase(),
    )?.person?.name;
    return `Updated ${name ? 'by <strong>' + name + '</strong>' : ''} on <strong>${date}</strong>`;
  }
}
