import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  QuestionnaireFormModel,
  QuestionnaireFormValues,
} from 'src/app/models/aliases';
import { Questionnaire, QuestionnaireAnswer } from 'src/app/models/interfaces';
import { getPageConfig as getAnswerSummaryPageConfig } from './answer-summary.field-configs';
import { getPageConfig as getChangeSummaryPageConfig } from './change-summary.field-configs';
import { evalExpression } from './contract-express.functions';
import { getPageConfig as getQuestionnairePageConfig } from './questionnaire.field-configs';

export const REPEAT_GROUP_SUFFIX = '_repeat';
export const ALL_OPTION_TEXT = 'dballoption';

@Injectable({
  providedIn: 'root',
})
export class ContractExpressService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  appendScript(renderer: Renderer2, scripts: string): void {
    const scriptsElement = this.document.getElementById('ceq-scripts');
    if (scriptsElement !== null) {
      scriptsElement.parentNode?.removeChild(scriptsElement);
    }
    const script = renderer.createElement('script');
    script.id = 'ceq-scripts';
    script.type = 'text/javascript';
    script.text = `var cePage = ${scripts};`;
    renderer.appendChild(this.document.body, script);
  }

  eval<T>(expression: string, model?: Record<string, unknown>): T | null {
    return evalExpression<T>(expression, model);
  }

  getAnswerSummaryFieldConfigs(
    model?: QuestionnaireFormModel,
  ): FormlyFieldConfig[] {
    return getAnswerSummaryPageConfig(model);
  }

  getFormFieldConfigs(
    model: QuestionnaireFormModel,
    options?: Record<string, unknown>,
  ): FormlyFieldConfig[] {
    return getQuestionnairePageConfig(model, options);
  }

  getPageGuidance(model?: QuestionnaireFormModel): string {
    const [pageData] = (window as any).cePage || [];
    if (pageData) {
      const { activatedpage, questionnairepages } = pageData as Questionnaire;
      const { guidance, guidanceexpression } =
        questionnairepages[activatedpage];
      return guidanceexpression
        ? evalExpression<string[]>(guidanceexpression, model)?.join('') || ''
        : guidance || '';
    }

    return '';
  }

  getChangeReportFieldConfigs(
    previous: QuestionnaireFormModel,
    current: QuestionnaireFormModel,
  ): FormlyFieldConfig[] {
    return getChangeSummaryPageConfig(previous, current);
  }

  mapFormValuesToAnswers(
    values: QuestionnaireFormValues,
  ): QuestionnaireAnswer[] {
    return Object.entries(values).reduce<QuestionnaireAnswer[]>(
      (answers, [question, { answer, answerLabel, questionPrompt }]) => {
        // if answer is an array, then it's coming from a repeater field array
        if (Array.isArray(answer)) {
          answer.forEach((group, index) => {
            const repeatContext = index + 1;
            if (group === null) {
              answers.push({
                question,
                questionPrompt,
                answer: '',
                known: false,
                repeatContext,
              });
            } else if (typeof group === 'string') {
              // It's an array of string - multivalued field.
              answers.push({
                question,
                questionPrompt,
                answer: group,
                repeatContext,
              });
            } else {
              Object.entries(group || {}).forEach(([subQuestion, subAnswer]) =>
                answers.push({
                  question: subQuestion,
                  answer: subAnswer?.toString(),
                  answerLabel,
                  repeatContext,
                  repeatGroupName: question,
                }),
              );
            }
          });
          if (question.includes(REPEAT_GROUP_SUFFIX)) {
            // Update corresponding repeater group counter that CE requires.
            answers.push({
              question: question.replace(REPEAT_GROUP_SUFFIX, ''),
              questionPrompt,
              answer: answer.length.toString(),
            });
          } else if (answer.length === 0) {
            // If it's not a repeat group and an empty array,
            // set it's value to empty string.
            answers.push({
              question,
              questionPrompt,
              answer: '',
              known: true,
            });
          }
        } else {
          const known = answer === null ? false : undefined;
          answers.push({
            question,
            questionPrompt,
            answer: answer?.toString(),
            answerLabel,
            known,
          });
        }

        return answers;
      },
      [],
    );
  }
}
