import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FieldWrapper } from '@ngx-formly/core';
import { BehaviorSubject, Subject, map, startWith, takeUntil } from 'rxjs';
import {
  ActivePageService,
  ActiveProjectService,
  ContractExpressService,
  QuestionnaireService,
} from 'src/app/core/services';
import { QuestionnaireAnswer } from 'src/app/models/interfaces';

@Component({
  selector: 'app-questionnaire-field-wrapper',
  templateUrl: './questionnaire-field-wrapper.component.html',
})
export class QuestionnaireFieldWrapperComponent
  extends FieldWrapper
  implements OnInit, OnDestroy
{
  destroyed$ = new Subject<boolean>();
  label$ = new BehaviorSubject<string>('');
  requiredFieldLabel$ = this.route.data.pipe(
    map((data) =>
      data['allowIncompletePage']
        ? 'This answer is missing'
        : 'This field is required',
    ),
  );
  showError$ = this.questionnaireService.showErrors$;
  updatedBy$ = this.activePageService.answerChanges$.pipe(
    map((answerChanges) => {
      const answerChange = answerChanges.find(
        ({ variableName }) =>
          variableName?.toLowerCase() === this.key.toString().toLowerCase(),
      );

      return answerChange
        ? this.formatUpdatedByLabel(answerChange.respondentInfoAnswer)
        : '';
    }),
  );

  get hideLabel(): boolean {
    const parent = this.field?.parent;
    if (
      parent &&
      Number(parent.key) &&
      parent.parent?.props &&
      parent.parent.props['repeatTitles'] &&
      (parent.parent.fieldArray as any)?.fieldGroup?.length === 1
    ) {
      return true;
    }
    return false;
  }

  constructor(
    private activePageService: ActivePageService,
    private activeProjectService: ActiveProjectService,
    private contractExpressService: ContractExpressService,
    private questionnaireService: QuestionnaireService,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.props['promptexpression']) {
      this.initDynamicPrompt();
    } else {
      this.label$.next(
        this.field.type === 'checkbox'
          ? this.props['prompt']
          : this.props.label,
      );
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private initDynamicPrompt(): void {
    this.formControl.root.valueChanges
      .pipe(takeUntil(this.destroyed$), startWith(''))
      .subscribe(() =>
        this.label$.next(
          this.contractExpressService
            .eval<string[]>(this.props['promptexpression'], this.model)
            ?.join('') || '',
        ),
      );
  }

  private formatUpdatedByLabel(answer: QuestionnaireAnswer): string {
    const { created, createdByUser } = answer;

    if (!created || !createdByUser) {
      return '';
    }

    const date = new Date(created).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
    });
    const name = this.activeProjectService.project?.projectPeople.find(
      ({ personId }) => personId.toLowerCase() === createdByUser.toLowerCase(),
    )?.person?.name;
    return `Updated ${name ? 'by <strong>' + name + '</strong>' : ''} on <strong>${date}</strong>`;
  }
}
