<div class="modal-body">
  <div class="d-flex flex-column gap-3 text-center">
    <div class="cx-loading" style="height: auto">
      @if (isLoading()) {
        <svg
          class="cx-loading__spinner"
          width="64px"
          height="64px"
          viewBox="0 0 64 64"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            class="path"
            fill="none"
            stroke-width="8"
            stroke-linecap="round"
            cx="32"
            cy="32"
            r="28"
          ></circle>
        </svg>
      } @else {
        <svg
          cdsIcon="checkmark--filled"
          class="text-success"
          size="32"
          fill="currentColor"
        ></svg>
      }
    </div>

    <h6>{{ title() }}</h6>

    @if (!isLoading() && navigateUrl()) {
      <div>
        <a
          [href]="navigateUrl()"
          target="_blank"
          class="btn btn-primary cx-btn"
          (click)="closeModal()"
        >
          Open in new tab
          <svg
            cdsIcon="launch"
            class="ms-2"
            fill="currentColor"
            size="16"
          ></svg>
        </a>
      </div>
    }
  </div>
</div>
