import { Component, inject, signal } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { IconsModule } from 'src/app/core/icons.module';
import { Modal } from 'src/app/models/enums';

@Component({
  selector: 'app-preview-questionnaire-modal',
  standalone: true,
  imports: [IconsModule],
  templateUrl: './preview-questionnaire-modal.component.html',
})
export class PreviewQuestionnaireModalComponent {
  title = signal('Please wait...');
  navigateUrl = signal('');
  isLoading = signal(true);

  private modalService = inject(BsModalService);

  closeModal(): void {
    this.modalService.hide(Modal.PreviewQuestionnaire);
  }
}
