import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { delayWhen, finalize } from 'rxjs';
import { IconsModule } from 'src/app/core/icons.module';
import {
  ActiveProjectService,
  ContractsService,
  RespondentsService,
  ToastService,
} from 'src/app/core/services';
import { RespondentMinorStatus } from 'src/app/models/enums';

@Component({
  selector: 'app-mark-complete-modal',
  standalone: true,
  imports: [IconsModule, CommonModule, FormsModule],
  templateUrl: './mark-complete-modal.component.html',
  styles: [
    `
      .select-file-btn:hover,
      .select-file-btn:focus {
        background-color: var(--bs-primary) !important;
        color: white !important;
        border-color: var(--bs-primary) !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkCompleteModalComponent {
  private activeProjectService = inject(ActiveProjectService);
  private contractsService = inject(ContractsService);
  private respondentsService = inject(RespondentsService);
  private toastService = inject(ToastService);
  private modalRef = inject(BsModalRef);

  respondentId: string = '';
  projectId = this.activeProjectService.project?.id || '';
  uploadEnabledClasses =
    'btn btn-primary d-flex justify-content-between align-items-center gap-2 w-100';
  uploadDisabledClasses =
    'btn btn-secondary d-flex justify-content-between align-items-center gap-2 w-100';

  selectedFile = signal<File | null>(null);
  selectedFileName = computed(() => this.selectedFile()?.name ?? '');
  skipDocumentUpload = signal(false);
  isSubmitting = signal(false);
  canComplete = computed(
    () =>
      (this.selectedFile() !== null || this.skipDocumentUpload()) &&
      !this.isSubmitting(),
  );

  onFileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files?.length) {
      this.selectedFile.set(target.files[0]);
    }
  }

  onComplete(): void {
    if (!this.canComplete()) return;

    this.isSubmitting.set(true);

    if (this.skipDocumentUpload()) {
      this.respondentsService
        .updateRespondent(this.respondentId, {
          questionnaireMinorStatus:
            RespondentMinorStatus.ManuallyMarkedComplete,
          questionnaireStatus: 'CompleteSigned',
        })
        .pipe(
          delayWhen(({ projectId }) =>
            this.respondentsService.fetchProjectRespondents(projectId),
          ),
          finalize(() => {
            this.isSubmitting.set(false);
            this.modalRef.hide();
          }),
        )
        .subscribe();
      return;
    }

    const file = this.selectedFile();
    if (file) {
      this.contractsService
        .uploadSignedDocument(this.respondentId, file)
        .pipe(
          delayWhen(() =>
            this.respondentsService.fetchProjectRespondents(this.projectId),
          ),
          finalize(() => {
            this.isSubmitting.set(false);
            this.modalRef.hide();
          }),
        )
        .subscribe({
          error: () => {
            this.toastService.show({
              type: 'info',
              header: 'Error',
              text: 'Failed to upload document. Please try again.',
            });
          },
        });
    }
  }

  onCancel(): void {
    this.modalRef.hide();
  }
}
