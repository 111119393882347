import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FieldArrayType, FormlyFieldConfig } from '@ngx-formly/core';
import { map } from 'rxjs';
import {
  ActivePageService,
  ActiveProjectService,
  QuestionnaireService,
  REPEAT_GROUP_SUFFIX,
} from 'src/app/core/services';
import {
  QuestionnaireAnswer,
  RepeatTableColumnConfig,
} from 'src/app/models/interfaces';
import { RadioComponent } from '../..';

@Component({
  selector: 'app-repeat-table',
  templateUrl: './repeat-table.component.html',
})
export class RepeatTableComponent extends FieldArrayType {
  requiredFieldLabel$ = this.route.data.pipe(
    map((data) =>
      data['allowIncompletePage']
        ? 'This answer is missing'
        : 'This field is required',
    ),
  );
  showError$ = this.questionnaireService.showErrors$;
  updatedBy$ = this.activePageService.answerChanges$.pipe(
    map((answerChanges) => {
      const answerChange = answerChanges.find(
        ({ respondentInfoAnswer, variableName }) =>
          variableName?.toLowerCase() === this.fieldKey ||
          respondentInfoAnswer.repeatGroupName === this.key,
      );

      return answerChange
        ? this.formatUpdatedByLabel(
            answerChange.respondentInfoAnswer as Required<QuestionnaireAnswer>,
          )
        : null;
    }),
  );

  get columns(): RepeatTableColumnConfig[] {
    return (
      this.props['columns'] ||
      this.fieldArray.fieldGroup?.map((config) => {
        const key = config.key as string;
        const label = config.props?.label || '';
        const width = config.type === RadioComponent ? '12rem' : undefined;
        return { key, label, width };
      }) ||
      []
    );
  }

  get fieldArray() {
    return this.field.fieldArray as FormlyFieldConfig;
  }

  get fieldKey() {
    return (this.field.key as string).replace(REPEAT_GROUP_SUFFIX, '');
  }

  get labels() {
    return (
      this.props['labels'] || {
        add_first: 'Add one or more entries',
        add_more: 'Add another entry',
      }
    );
  }

  get preventColumnGrowth(): boolean {
    return this.columns.every((column) => column.width);
  }

  get showTableHeader(): boolean {
    return this.columns.some(({ label }) => label);
  }

  constructor(
    private activePageService: ActivePageService,
    private activeProjectService: ActiveProjectService,
    private questionnaireService: QuestionnaireService,
    private route: ActivatedRoute,
  ) {
    super();
  }

  addGroup(): void {
    // Certain CE fields require numeric counter of repeater groups
    // to be updated in real time for usage property to work correctly.
    // Adding a "band-aid" here for conditional fields to work.
    if (
      this.field.parent?.model &&
      !this.field.parent.model.hasOwnProperty(this.fieldKey)
    ) {
      this.field.parent.model[this.fieldKey] = 1;
    }

    this.add();
  }

  getField(name: string, index: number) {
    if (Array.isArray(this.field.fieldGroup)) {
      const row = this.field.fieldGroup[index];
      const fieldConfig = row.fieldGroup?.find(
        ({ key }) => key === name.toLowerCase(),
      );
      return fieldConfig;
    }

    return null;
  }

  removeGroup(index: number): void {
    this.formControl.markAsTouched();
    if (this.formControl.length === 1) {
      Object.keys(this.formControl.at(0).getRawValue()).forEach((key) => {
        this.formControl.at(0).get(key)?.setValue('', { emitEvent: false });
      });
      this.formControl.markAsDirty();
      this.formControl.updateValueAndValidity();
    }
    this.remove(index, { markAsDirty: true });
    this.formControl.updateValueAndValidity();
  }

  private formatUpdatedByLabel(answer: Required<QuestionnaireAnswer>): string {
    const { created, createdByUser } = answer;
    const date = new Date(created).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
    });
    const name = this.activeProjectService.project?.projectPeople.find(
      ({ personId }) => personId.toLowerCase() === createdByUser.toLowerCase(),
    )?.person?.name;
    return `Updated ${name ? 'by <strong>' + name + '</strong>' : ''} on <strong>${date}</strong>`;
  }
}
