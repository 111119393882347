import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { IconsModule } from 'src/app/core/icons.module';
import { Modal } from 'src/app/models/enums';

@Component({
  selector: 'app-confirm-save-changes',
  standalone: true,
  imports: [IconsModule],
  templateUrl: './confirm-save-changes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmSaveChangesComponent {
  @Input() apply = () => {};
  @Input() discard = () => {};
  @Input() onNavigation = false;

  confirmed$ = new Subject<boolean>();

  get bodyText() {
    return this.onNavigation
      ? 'Please confirm that you would like to save the changes to this page before navigating.'
      : 'Please confirm that you would like to save the changes to this page.';
  }

  get modalTitle() {
    return this.onNavigation ? 'Unsaved changes' : 'Save and process changes';
  }

  constructor(private bsModalService: BsModalService) {}

  closeModal(): void {
    this.bsModalService.hide(Modal.ConfirmSaveChanges);
  }
}
