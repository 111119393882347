<div class="bg-white container-xl mx-auto p-3 p-lg-5 position-relative">
  @if (title()) {
    <div
      class="position-relative h4"
      [ngClass]="{ 'pb-3': !showTitlePrefix(), 'py-3': showTitlePrefix() }"
    >
      @if (showTitlePrefix()) {
        <div class="position-absolute text-muted">
          {{ title() | pageTitle: true : false }}
        </div>
      }
      <div [ngClass]="{ 'mb-5 ms-5 ps-3': showTitlePrefix() }">
        {{ title() | pageTitle: false : true }}
        @if (subtitle) {
          <span class="fw-normal ms-3">{{ subtitle }}</span>
        }
        @if (category) {
          <span class="fw-normal text-muted ms-3">({{ category }})</span>
        }
      </div>
    </div>
  }

  <div
    *ngIf="guidance"
    appGlossaryTerm
    class="my-3"
    [innerHTML]="guidance | safeHtml"
    style="max-width: 80ch; white-space: pre-wrap"
  ></div>

  <form autocomplete="off" [formGroup]="form">
    <formly-form
      [form]="form"
      [fields]="fields"
      [model]="model"
      [options]="options"
    ></formly-form>
  </form>

  <ng-content></ng-content>

  <div
    class="bg-white position-absolute start-0 top-0 h-100 w-100"
    [class.d-none]="(loading$ | async) === false"
    style="opacity: 50%"
  ></div>
</div>
