import { Injectable } from '@angular/core';
import { Job } from 'src/app/models/interfaces';
import { OdataBackendService } from '..';

@Injectable({
  providedIn: 'root',
})
export class JobsService {
  constructor(private odataBackend: OdataBackendService) {}

  loadProjectJobs(projectId: string) {
    return this.odataBackend.getEntitySet<Job>('Jobs', {
      expand: {
        jobLogs: { select: ['stage'] },
      },
      filter: {
        projectId: { eq: { type: 'guid', value: projectId } },
      },
      select: ['created', 'jobType', 'modified', 'status'],
      orderBy: [['created', 'desc']],
    });
  }
}
