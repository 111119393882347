@for (row of field.fieldGroup; track field; let i = $index) {
  @if (editingMap.get(i)) {
    <div class="d-flex gap-5 align-items-center my-2">
      <div class="fw-bold">{{ i + 1 }} of {{ field.fieldGroup?.length }}</div>
      <button
        class="btn btn-danger cx-btn cx-btn--ghost ms-2"
        type="button"
        (click)="removeGroup(i)"
      >
        Delete
        <svg cdsIcon="trash-can" fill="currentColor" size="16"></svg>
      </button>
    </div>

    <div class="hide-updatedBy">
      <div class="row g-2">
        <div class="col">
          <formly-field
            *ngIf="getField('signatory_ipasa_awardtype', i) as subfield"
            [field]="subfield"
          ></formly-field>
        </div>
        <div class="col">
          <formly-field
            *ngIf="getField('signatory_ipasa_planname', i) as subfield"
            [field]="subfield"
          ></formly-field>
        </div>
        <div class="col">
          <formly-field
            *ngIf="getField('signatory_ipasa_stockclass', i) as subfield"
            [field]="subfield"
          ></formly-field>
        </div>
        <div class="col-auto">
          <formly-field
            *ngIf="getField('signatory_ipasa_grantdate', i) as subfield"
            [field]="subfield"
          ></formly-field>
        </div>
      </div>
      <div class="row g-2">
        <div class="col-auto">
          <formly-field
            *ngIf="getField('signatory_ipasa_totalshares', i) as subfield"
            [field]="subfield"
          ></formly-field>
        </div>
        <div class="col-auto">
          <formly-field
            *ngIf="getField('signatory_ipasa_vestedshares', i) as subfield"
            [field]="subfield"
          ></formly-field>
        </div>
        <div class="col-auto">
          <formly-field
            *ngIf="getField('signatory_ipasa_unvestedshares', i) as subfield"
            [field]="subfield"
          ></formly-field>
        </div>
      </div>
      <div class="row g-2">
        <div class="col-2">
          <formly-field
            *ngIf="getField('signatory_ipasa_hasexerciseprice', i) as subfield"
            [field]="subfield"
          ></formly-field>
        </div>
        <div class="col-auto">
          <formly-field
            *ngIf="getField('signatory_ipasa_exerciseprice', i) as subfield"
            [field]="subfield"
          ></formly-field>
        </div>
      </div>
      <div class="row g-2">
        <div class="col-2">
          <formly-field
            *ngIf="getField('signatory_ipasa_hasexpirationdate', i) as subfield"
            [field]="subfield"
          ></formly-field>
        </div>
        <div class="col-auto">
          <formly-field
            *ngIf="getField('signatory_ipasa_expirationdate', i) as subfield"
            [field]="subfield"
          ></formly-field>
        </div>
      </div>
      <div class="row g-2">
        <div class="col-2">
          <formly-field
            *ngIf="getField('signatory_ipasa_hasvestingdate', i) as subfield"
            [field]="subfield"
          ></formly-field>
        </div>
        <div class="col-auto">
          <formly-field
            *ngIf="getField('signatory_ipasa_vestingdate', i) as subfield"
            [field]="subfield"
          ></formly-field>
        </div>
        <div class="col-auto">
          <formly-field
            *ngIf="getField('signatory_ipasa_vestingschedule', i) as subfield"
            [field]="subfield"
          ></formly-field>
        </div>
      </div>
      <div class="row g-2">
        <div class="col-2">
          <formly-field
            *ngIf="getField('signatory_ipasa_grantedeip', i) as subfield"
            [field]="subfield"
          ></formly-field>
        </div>
        <div class="col-auto">
          <formly-field
            *ngIf="getField('signatory_ipasa_eipthreshold', i) as subfield"
            [field]="subfield"
          ></formly-field>
        </div>
        <div class="col-auto">
          <formly-field
            *ngIf="getField('signatory_ipasa_eiptarget', i) as subfield"
            [field]="subfield"
          ></formly-field>
        </div>
        <div class="col-auto">
          <formly-field
            *ngIf="getField('signatory_ipasa_eipmaximum', i) as subfield"
            [field]="subfield"
          ></formly-field>
        </div>
        <div class="col">
          <formly-field
            *ngIf="getField('signatory_ipasa_materialterms', i) as subfield"
            [field]="subfield"
          ></formly-field>
        </div>
      </div>
      <div class="row g-2">
        <div class="col-2">
          <formly-field
            *ngIf="getField('signatory_ipasa_modified', i) as subfield"
            [field]="subfield"
          ></formly-field>
        </div>
        <div class="col">
          <formly-field
            *ngIf="getField('signatory_ipasa_modifieddetails', i) as subfield"
            [field]="subfield"
          ></formly-field>
        </div>
      </div>
    </div>
    <div class="d-flex gap-2">
      <button
        class="btn btn-outline-primary cx-btn"
        type="button"
        (click)="toggleEdit(i)"
      >
        Save changes
        <svg cdsIcon="save" fill="currentColor" size="16"></svg>
      </button>
      <button
        class="btn cx-btn cx-btn--ghost"
        type="button"
        (click)="toggleEdit(i)"
      >
        Cancel
      </button>
    </div>
    <hr />
  } @else {
    <div
      class="bg-light my-2 px-3"
      [ngClass]="{
        'border border-danger missing-answer':
          (showError$ | async) && !isRowValid(i),
      }"
    >
      <div class="d-flex gap-3 align-items-center">
        <div class="fw-bold">{{ i + 1 }} of {{ field.fieldGroup?.length }}</div>
        <button
          class="btn btn-primary cx-btn cx-btn--ghost ms-2"
          type="button"
          (click)="toggleEdit(i)"
        >
          Edit
          <svg cdsIcon="edit" fill="currentColor" size="16"></svg>
        </button>
        <button
          class="btn btn-danger cx-btn cx-btn--ghost cx-btn--icon ms-2"
          type="button"
          (click)="removeGroup(i)"
        >
          <svg cdsIcon="trash-can" fill="currentColor" size="16"></svg>
        </button>
      </div>
      <div class="row py-3">
        <div class="col-3 d-flex flex-column gap-1">
          <div class="fw-bold">
            {{ formControl.value[i]["signatory_ipasa_awardtype"] }}
          </div>
          <div>
            {{ formControl.value[i]["signatory_ipasa_planname"] }}
          </div>
          <div>
            {{ formControl.value[i]["signatory_ipasa_stockclass"] }}
          </div>
        </div>
        <div class="col-3">
          <div class="row gy-2">
            <div class="col-7">Total Shares</div>
            <div class="col-5">
              @if (
                formControl.value[i]["signatory_ipasa_totalshares"];
                as value
              ) {
                {{ value | number }}
              }
            </div>
            <div class="col-7">Vested &#64; FYE</div>
            <div class="col-5">
              @if (
                formControl.value[i]["signatory_ipasa_vestedshares"];
                as value
              ) {
                {{ value | number }}
              }
            </div>
            <div class="col-7">Unvested &#64; Shares</div>
            <div class="col-5">
              @if (
                formControl.value[i]["signatory_ipasa_unvestedshares"];
                as value
              ) {
                {{ value | number }}
              }
            </div>
            <div class="col-7">Exercise Price</div>
            <div class="col-5">
              @if (
                formControl.value[i]["signatory_ipasa_hasexerciseprice"] &&
                formControl.value[i]["signatory_ipasa_exerciseprice"]
              ) {
                {{
                  formControl.value[i]["signatory_ipasa_exerciseprice"]
                    | currency: "USD" : "symbol" : "1.0-4"
                }}
              } @else {
                No
              }
            </div>
            <div class="col-7">Grant Date</div>
            <div class="col-5">
              @if (
                formControl.value[i]["signatory_ipasa_grantdate"];
                as value
              ) {
                {{ value | date: "shortDate" }}
              }
            </div>
            <div class="col-7">Expiration Date</div>
            <div class="col-5">
              @if (
                formControl.value[i]["signatory_ipasa_hasexpirationdate"] &&
                formControl.value[i]["signatory_ipasa_expirationdate"]
              ) {
                {{
                  formControl.value[i]["signatory_ipasa_expirationdate"]
                    | date: "shortDate"
                }}
              } @else {
                No
              }
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row gy-3">
            <div class="col-4">Vesting Date</div>
            <div class="col-8">
              @if (
                formControl.value[i]["signatory_ipasa_hasvestingdate"] &&
                formControl.value[i]["signatory_ipasa_vestingdate"]
              ) {
                {{
                  formControl.value[i]["signatory_ipasa_vestingdate"]
                    | date: "shortDate"
                }}
              } @else {
                No
              }
            </div>
            @if (formControl.value[i]["signatory_ipasa_hasvestingdate"]) {
              <div class="col-4">Vesting Schedule</div>
              <div class="col-8">
                {{ formControl.value[i]["signatory_ipasa_vestingschedule"] }}
              </div>
            }
            <div class="col-4">Pursuant to equity incentive plan?</div>
            <div class="col-8 d-flex flex-column gap-1">
              @if (formControl.value[i]["signatory_ipasa_grantedeip"]) {
                <div class="row">
                  <div class="col">
                    <div class="text-muted">Threshold</div>
                    <div>
                      {{ formControl.value[i]["signatory_ipasa_eipthreshold"] }}
                    </div>
                  </div>
                  <div class="col">
                    <div class="text-muted">Target</div>
                    <div>
                      {{ formControl.value[i]["signatory_ipasa_eiptarget"] }}
                    </div>
                  </div>
                  <div class="col">
                    <div class="text-muted">Max</div>
                    <div>
                      {{ formControl.value[i]["signatory_ipasa_eipmaximum"] }}
                    </div>
                  </div>
                </div>

                <div>
                  {{ formControl.value[i]["signatory_ipasa_materialterms"] }}
                </div>
              } @else {
                No
              }
            </div>
            <div class="col-4">Modified in 2024?</div>
            <div class="col-8">
              @if (formControl.value[i]["signatory_ipasa_modified"]) {
                {{ formControl.value[i]["signatory_ipasa_modifieddetails"] }}
              } @else {
                No
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

<div class="mb-3">
  <button
    class="btn btn-outline-primary cx-btn"
    [class.w-100]="!field.fieldGroup?.length"
    [ngStyle]="field.fieldGroup?.length ? {} : { borderStyle: 'dashed' }"
    type="button"
    (click)="addGroup()"
  >
    {{
      field.fieldGroup?.length ? "Add another award" : "Add one or more awards"
    }}
    <svg cdsIcon="add" fill="currentColor" size="16"></svg>
  </button>

  @if ((showError$ | async) && field.formControl.value.length === 0) {
    <div class="missing-answer d-flex gap-2 py-2 text-danger">
      <svg cdsIcon="warning--filled" fill="currentColor" size="16"></svg>
      {{ requiredFieldLabel$ | async }}
    </div>
  }

  @if (updatedBy$ | async; as updatedBy) {
    <div
      class="alert alert-warning mt-2"
      [innerHTML]="updatedBy"
      style="max-width: 640px"
    ></div>
  }
</div>
